import React from 'react';
import Navigation from '../Component/Navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import image1 from '../Asset/group-people-working-out-business-plan-office.jpg'; // Import your images
import image2 from '../Asset/young-african-american-man-s-playing-vr-glasses-neon-light-gradient.jpg';
import image3 from '../Asset/6530.jpg';

const Home = () => {
  return (
    <div>
      <div className="relative">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000 }} // Set autoplay delay to 3000 milliseconds (3 seconds)
          style={{ height: '100vh' }}
        >
          <SwiperSlide
            style={{ backgroundImage: `url(${image1})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="h-full flex flex-col justify-center items-center">
              <div className="text-white font-bold text-4xl">
                Welcome to Quadrapoint Technologies
              </div>
              <div className="text-orange-500 text-lg mt-4">
                We specialize in helping businesses thrive through innovative solutions.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            style={{ backgroundImage: `url(${image2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="h-full flex flex-col justify-center items-center">
              <div className="text-white font-bold text-4xl">
                Discover a New Dimension
              </div>
              <div className="text-white text-lg mt-4">
                Experience cutting-edge technology with Quadrapoint Technologies.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            style={{ backgroundImage: `url(${image3})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="h-full flex flex-col justify-center items-center">
              <div className="text-white font-bold text-4xl">
                Join Us on the Journey
              </div>
              <div className="text-lg mt-4 text-orange-500">
                Let's build the future together with Quadrapoint Technologies.
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <Navigation />
      </div>
    </div>
  );
};

export default Home;
