import React from 'react';
import { motion } from 'framer-motion';

const placeholderLogos = [
  'https://via.placeholder.com/150',
  'https://via.placeholder.com/150',
  'https://via.placeholder.com/150',
  'https://via.placeholder.com/150',
  'https://via.placeholder.com/150',
  'https://via.placeholder.com/150',
];

const OurPortfolio = () => {
  return (
    <motion.div
      className=" h-screen pt-10 poppins-bold"
      id="portfolio"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase poppins-bold ">
            Client who trust our work
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-orange-500 sm:text-4xl">
            Trusted by leading companies
          </p>
          <p className="mt-4 max-w-2xl text-xl text-black-500 lg:mx-auto poppins-bold ">
            Here are some of the companies who have trusted us with their
            projects.
          </p>
        </div>
        <div className="mt-10 grid grid-cols-2 gap-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
          {placeholderLogos.map((logo, index) => (
            <motion.div
              key={index}
              className="flex"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.5 }}
            >
              <img
                src={logo}
                alt={`Logo ${index}`}
                className="h-24 w-auto"
              />
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default OurPortfolio;
