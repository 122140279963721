import React from 'react'

import Home from '../Pages/Home';
import About from '../Pages/About.jsx';
import Service from '../Pages/Service.jsx';
import OurPortfolio from '../Pages/OurPortfolio.jsx';
import Footer from '../Component/Footer.jsx';
import Contact from '../Pages/Contact';

const LandingPage = () => {
  return (
    <div>

<Home/>
<About/>
<Service/> 
 <OurPortfolio/> 
<Contact/>
<Footer/>

    </div>
  )
}

export default LandingPage