import React from 'react';

const MediaDigitalMarketingPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <section className="bg-white py-20">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">Media & Digital Marketing Company</h1>
            <p className="text-lg text-gray-600 mb-8">Your partner in digital success</p>
            <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-8 rounded-full transition duration-300">
              Get Started
            </button>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <img src="https://via.placeholder.com/600x400" alt="About" className="rounded-lg shadow-lg" />
            </div>
            <div className="md:w-1/2 md:pl-10">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">About Us</h2>
              <p className="text-lg text-gray-600 mb-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec dui in eros pharetra suscipit.
                Aliquam feugiat, sem ac tempor pellentesque, arcu nunc eleifend neque, ac eleifend elit risus nec dui.
              </p>
              <p className="text-lg text-gray-600 mb-6">
                Integer id ex sit amet nunc malesuada volutpat. Nulla id nisi orci. Mauris nec nulla vitae eros
                commodo fermentum.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="bg-gray-800 text-white py-20">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-8">Our Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-gray-900 rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold mb-4">Social Media Management</h3>
                <p className="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
              <div className="bg-gray-900 rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold mb-4">Content Creation</h3>
                <p className="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
              <div className="bg-gray-900 rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold mb-4">SEO Optimization</h3>
                <p className="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Contact Us</h2>
            <p className="text-lg text-gray-600 mb-8">
              Have questions or want to discuss a project? Contact us today!
            </p>
            <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-8 rounded-full transition duration-300">
              Contact Us
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MediaDigitalMarketingPage;
