import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion';


const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    emailjs
      .sendForm('service_rmp3tlv', 'template_1fmefci', e.target, 'znfNIW2RdsrhYQfIH')
      .then(
        (result) => {
          console.log(result.text);
          setSubmitting(false);
          setSubmitted(true);
        },
        (error) => {
          console.log(error.text);
          setSubmitting(false);
          setError(error);
        }
      );

    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className="container mx-auto py-8" id="contact">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-2xl mb-4 font-bold text-orange-500 text-center"
      >
        Contact Us
      </motion.h1>
      <motion.form
        onSubmit={handleSubmit}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-lg mx-auto"
      >
        <div className="mb-4">
          <label htmlFor="name" className="block text-orange-500 poppins-semibold ">
            Name
          </label>
          <motion.input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            required
            whileFocus={{ scale: 1.05 }}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-orange-500 poppins-semibold">
            Email
          </label>
          <motion.input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            required
            whileFocus={{ scale: 1.05 }}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-orange-500 poppins-semibold">
            Message
          </label>
          <motion.textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            required
            whileFocus={{ scale: 1.05 }}
          ></motion.textarea>
        </div>
        {submitting && (
          <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="text-gray-700 font-bold">
            Sending...
          </motion.p>
        )}
        {submitted && (
          <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="text-green-600 font-bold">
            Message submitted successfully!
          </motion.p>
        )}
        {error && (
          <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="text-red-600 font-bold">
            An error occurred. Please try again later.
          </motion.p>
        )}
        <motion.button
          type="submit"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition duration-500 w-full"
        >
          Send
        </motion.button>
      </motion.form>
 
 
    </div>
  );
};

export default Contact;
