import React from 'react';

const Footer = () => {
  return (
    <footer className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex justify-center md:order-2">
            <button className="poppins-semibold text-white hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium ">
              Home
            </button>
            <button className="text-white poppins-semibold hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium">
              About
            </button>
            <button className="text-white poppins-semibold hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium">
              Services
            </button>
            <button className="text-white poppins-semibold hover:text-orange-500 px-3 py-2 rounded-md text-sm font-medium"></button>

            <button className="text-white hover:text-gray-400 px-3 py-2 rounded-md text-sm font-medium">
              Home
            </button>
            <button className="text-white hover:text-gray-400 px-3 py-2 rounded-md text-sm font-medium">
              About
            </button>
            <button className="text-white hover:text-gray-400 px-3 py-2 rounded-md text-sm font-medium">
              Services
            </button>
            <button className="text-white hover:text-gray-400 px-3 py-2 rounded-md text-sm font-medium">

              Contact
            </button>
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base font-medium text-indigo-500">&copy; 2024 Quadrapoint. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
