import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ServiceCard = ({ icon, title, description, learnMoreLink }) => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate(learnMoreLink);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer hover:shadow-xl hover:opacity-90 transition duration-300" onClick={handleLearnMoreClick}>
      <div className="p-6 flex flex-col items-center">
        <svg className="w-12 h-12 text-orange-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          {icon}
        </svg>
        <h2 className="text-xl font-semibold text-gray-900 mt-4 mb-2 text-center">{title}</h2>
        <p className="text-base text-gray-700 leading-relaxed">{description}</p>

        <Link to={learnMoreLink} className="inline-block mt-6 bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 transition duration-300">
          Learn More
        </Link>

      </div>
    </div>
  );
};

const ServicePage = () => {
  const services = [
    {
      icon: <path d="M17.31 6.18l-4.93-4.93c-.64-.64-1.69-.64-2.33 0l-4.34 4.34c-.64.64-.64 1.69 0 2.33l4.34 4.34c.64.64 1.69.64 2.33 0l4.93-4.93c.4-.4.57-.9.57-1.38z" fill="currentColor" />,
      title: "Web Development", 
      description: "Crafting stunning websites tailored to your needs using the latest technologies.",
      learnMoreLink: "/web-development" , 
    },
    {
      icon: <path d="M8 9l3 3v11l5-5 3-3-11-8-3 3z" fill="currentColor" />,
      title: "Mobile App Development",
      description: "Building powerful mobile applications for iOS and Android platforms.",
      learnMoreLink: "/mobile-app-development",
    },
    {
      icon: <path d="M7.64 4.51l-.86-.51-4.8 4.8 1.78 1.78 4.8-4.8-.86-.51zm12.72 0l-.86-.51-4.8 4.8 1.78 1.78 4.8-4.8-.86-.51z" fill="currentColor" />,
      title: "Cloud Services",
      description: "Leveraging the cloud for scalable and efficient solutions for your business.",
      learnMoreLink: "/cloud-services",
    },
  ];

  return (
    <section className=" min-h-screen py-10">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl text-center mb-10 font-bold text-orange-500">OUR SERVICES</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicePage;
