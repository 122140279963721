
import React from 'react'
import LandingPage from './Pages/LandingPage'
import { Routes, Route } from 'react-router-dom'
import WebDevelopment from './Pages/WebDevelopment'
import MobileDevelopment from './Pages/MobileDevelopment'
import CloudService from './Pages/CloudService'




const App = () => {
  return (
    
      <Routes>
<Route path="/" element={<LandingPage />}></Route>
<Route path="/web-development" element={<WebDevelopment />}></Route>
<Route path="/mobile-app-development" element={<MobileDevelopment />}></Route>
<Route path="/cloud-services" element={<CloudService />}></Route>




      </Routes>

  
  )
}

export default App