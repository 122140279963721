import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import about from "../Asset/about us.jpg";
import Precious from '../Asset/wepik-export-2024021213580887PU.jpeg';


const About = () => {
  const skillTeam = [
    {
      img: Precious,
      position: "Chief Executive Officer",
      name: "Lorem"
    },
    {
      img: Precious,
      position: "Chief Executive Officer",
      name: "Lorem"
    },
    {
      img: Precious,
      position: "Chief Executive Officer",
      name: "Lorem"
    },
    {
      img: Precious,
      position: "Chief Executive Officer",
      name: "Lorem"
    }
  ];

  const [showElement1, setShowElement1] = useState(false);
  const [showElement2, setShowElement2] = useState(false);

  const [ref1, inView1] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const [ref2, inView2] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView1) {
      setShowElement1(true);
    }
    if (inView2) {
      setShowElement2(true);
    }
  }, [inView1, inView2]);

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 min-h-screen' id='about'>
        <div className='flex items-center justify-center'>
          <img src={about} alt='About Us' className='p-4 rounded-2xl' />
        </div>
        <div className='grid grid-rows-2 '>
          <div className='flex flex-col justify-center px-4 md:px-8 lg:px-12'>
            <div className='text-center text-orange-500 font-bold poppins-bold'>
              <h1 className=' poppins-bold'>Who We Are</h1>
            </div>
            <div className='text-center poppins-regular'>
              <p>
                We are a team of passionate individuals dedicated to creating innovative solutions that empower businesses and individuals alike. With a focus on excellence and creativity, we strive to exceed expectations and drive success for our clients.
              </p>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:px-8 lg:px-12'>
            <motion.div
              ref={ref1}
              initial={{ scale: 0 }}
              animate={showElement1 ? { scale: 1 } : {}}
              transition={{ duration: 0.5 }}
            >
              <h1 className='text-center poppins-bold text-orange-500'>What We Believe</h1>
              <p className='text-sm md:text-base'>We believe in the power of collaboration, creativity, and continuous improvement. By fostering a culture of trust, respect, and innovation, we empower our team to deliver exceptional results and exceed client expectations.</p>
            </motion.div>
            <motion.div
              ref={ref2}
              initial={{ scale: 0 }}
              animate={showElement2 ? { scale: 1 } : {}}
              transition={{ duration: 0.5 }}
            >
              <h1 className='text-center poppins-bold text-orange-500'>Our Mission</h1>
              <p className='text-sm md:text-base'>Our mission is to leverage technology to drive positive change and create value for our clients and communities. Through innovative solutions and exceptional service, we aim to make a lasting impact and contribute to a brighter future for all.</p>
            </motion.div>
          </div>
        </div>
      </div>

      <div className=' py-12 sm:py-24' id='team'>
        <div className='container mx-auto'>
          <h1 className='text-center text-2xl mb-8 poppins-bold text-orange-500'>OUR SKILLED TEAM</h1>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4 px-5">
            {skillTeam.map((member, index) => (
              <div key={index} className="relative flex flex-col items-center shadow-md">
                <img 
                  src={member.img} 
                  alt={member.name} 
                  className="w-full h-auto rounded-lg grayscale transition duration-300 transform hover:grayscale-0 hover:scale-105"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
                  <h2 className="text-lg font-semibold">{member.name}</h2>
                  <p className="text-gray-300 text-sm">{member.position}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
