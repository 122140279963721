import React from 'react';
import { Link } from 'react-router-dom';
import HeroImage from '../Asset/WebDevelopment/17918.jpg';
import GreatSoftware1 from "../Asset/WebDevelopment/Our Way of Building Image1.svg";
import node from '../Asset/Java.jpg';
import java from '../Asset/Python.svg';
import mongodb from '../Asset/mon 1.svg';
import php from '../Asset/PHP.jpg';
import rectangle18 from '../Asset/Rectangle 18.jpg';
import rectangle19 from '../Asset/Rectangle 19.jpg';


const WebDevelopment = () => {
  const approach = [
    { icon: "",
      title: "UX driven engineering",
      about: "We prioritize the user experience in every step of our development process. By understanding the needs and behaviors of users, we create intuitive and user-friendly products that delight our clients and their customers."
    },
    { icon: "",
      title: "Developing shared understanding",
      about: "Effective communication and collaboration are at the core of our development approach. We work closely with our clients to ensure a shared understanding of project goals, requirements, and timelines, fostering transparency and trust throughout the development process."
    },
    { icon: "",
      title: "Proven experience and expertise",
      about: "With years of experience and a highly skilled team of developers, we have a proven track record of delivering high-quality software solutions. Our expertise spans across various technologies and industries, allowing us to tackle complex challenges with confidence."
    },
    { icon: "",
      title: "Security and intellectual property",
      about: "We take security and intellectual property protection seriously. From implementing robust security measures to safeguarding our clients' proprietary information, we ensure the confidentiality, integrity, and availability of their data throughout the development lifecycle."
    },
    { icon: "",
      title: "Code review",
      about: "Our rigorous code review process ensures the quality and maintainability of our codebase. By conducting thorough code reviews, we identify and address issues early in the development cycle, resulting in cleaner, more efficient code and faster time to market."
    },
    { icon: "",
      title: "Quality assurance and testing",
      about: "Quality assurance is ingrained in our development process from the outset. We employ a comprehensive testing strategy, including unit testing, integration testing, and user acceptance testing, to ensure that our products meet the highest standards of quality and reliability."
    }
  ];

  const stack = [
    { node: <img src={java} alt="" srcset="" /> },
    { node: <img src={mongodb} alt="" srcset="" /> },
    { node: <img src={php} alt="" srcset="" /> },
    { node: <img src={node} alt="" srcset="" /> },
  ];
  
  return (
    <div className='min-h-screen font-sans'>
      {/* Hero Section */}
      <div className='flex flex-col md:flex-row px-5'>
        <div className='md:w-1/2 flex flex-col justify-center px-8 md:px-16 lg:px-32'>
          <p className='text-3xl text-orange-500 poppins-bold'>Empowering <span className='text-indigo-500 font-bold'>Ideas</span> with Technology</p> 
          <h2 className='font-extrabold text-xl mt-4 text-orange-500 poppins-bold'>Crafting <span className='text-indigo-500'>Digital Solutions</span> for Your Success</h2>
          <div className='py-5'>
            At our core, we believe in the power of technology to transform businesses and enrich lives. Let us be your partner in turning your ideas into reality.
          </div>
        </div>
        <div className='md:w-1/2 flex justify-center items-center'>
          <div className='max-w-md md:max-w-lg lg:max-w-xl p-8'>
            <img src={HeroImage} alt="Logo" className='w-full' />
          </div>
        </div>
      </div>

      {/* Trust Section */}
      <div className='grid grid-cols-1 md:grid-cols-2'>
        <div className='md:col-start-1 md:col-end-2 flex flex-col md:flex-row items-center'>
          <div className="md:w-1/2 flex flex-col justify-center px-8 md:px-14 ml-20 md:ml-0 pt-14 md:order-1">
            <div className="md:mt-14 flex items-center">
              <div>
                <p className="font-bold text-2xl text-orange-500">Leading Companies Trust Us to Develop</p>
              </div>
            </div>
            <div className=" mt-6">
              We specialize in building custom web solutions that align with your business goals and exceed your expectations.
            </div>
          </div>
        </div>
        <div className='max-w-md md:max-w-lg lg:max-w-xl p-12'>
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/EqxmKEFeimE?si=S4zqnWTcf0ZKS0Oy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>

      {/* Our Way of Building Section */}
      <div className='text-center pt-16'>
        <h1 className='font-bold text-2xl poppins-bold text-orange-500'>Our Approach to Building</h1>
        <span className='font-bold text-indigo-500  poppins-semibold'>Great Software</span>
        <div className='grid grid-cols-1 md:grid-cols-2'>
          <div className='md:col-start-1 md:col-end-2'>
            <div className='md:w-1/2 flex flex-col justify-center px-8 md:px-14 md:ml-20 pt-14'>
              <p className='font-semibold text-base poppins-bold text-orange-500'>Building High-Quality Software</p>
              <div className='pt-4 '>
                We are committed to delivering high-quality software solutions that drive innovation, enhance user experiences, and accelerate business growth.
              </div>
            </div>
          </div>
          <div className='max-w-md md:max-w-lg lg:max-w-xl p-12 md:ml-auto lg:pr-24 '>
            <img src={GreatSoftware1} alt="" />
          </div>
        </div>
      </div>

      {/* Image Section 1 */}
      <div className='grid grid-cols-1 md:grid-cols-2 '>
        <div className='max-w-md md:max-w-lg lg:max-w-xl p-12 md:ml-auto lg:pr-24'>
          <img src={rectangle18} alt="" className='w-full' />
        </div>
        <div className='md:w-1/2 flex flex-col justify-center px-8 md:px-14 md:ml-20 pt-14'>
          <p className='font-semibold text-base poppins-bold text-orange-500'>Tailored Solutions for Your Needs</p>
          <div className='pt-4'>
            We understand that every business is unique. That's why we offer tailored solutions designed to address your specific needs and challenges.
          </div>
        </div>
      </div>

      {/* Image Section 2 */}
      <div className='grid grid-cols-1 md:grid-cols-2 '>
        <div className='md:order-2'>
          <div className='max-w-md md:max-w-lg lg:max-w-xl p-12 md:ml-auto lg:pr-24'>
            <img src={rectangle19} alt="" className='w-full' />
          </div>
        </div>
        <div className='md:order-1'>
          <div className='md:w-1/2 flex flex-col justify-center px-8 md:px-14 md:ml-20 pt-14'>
            <p className='font-semibold text-base poppins-bold text-orange-500'>Innovative Solutions for Your Challenges</p>
            <div className=' pt-4 '>
              We thrive on tackling complex challenges and turning them into opportunities for innovation and growth.
            </div>
          </div>
        </div>
      </div>

      {/* Design and Development Approach */}
      <div className='pt-10'>
        <h1 className='text-orange-500 font-bold text-xl md:text-xl lg:text-2xl xl:text-xl text-center'>Our Design and Development Approach</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8 mt-10 px-5">
            {approach.map((item, index) => (
                <div key={index} className="border border-gray-300 rounded-lg shadow-md p-6">
                    <h2 className="font-semibold text-lg text-orange-500 mb-4">{item.title}</h2>
                    <p className="text-sm text-gray-700">{item.about}</p>
                </div>
            ))}
        </div>
      </div>

      {/* Tech Stack */}
      <div className='mt-5'>
        <h1 className=' text-indigo-600 text-center'>Our Tech Stack</h1>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-4">
            {stack.map((item, index) => (
                <div key={index} className="aspect-w-1 aspect-h-1">
                    <img src={item.node} className="object-cover w-full h-full" alt={`Tech Stack ${index}`} />
                </div>
            ))}
        </div>
      </div>

      {/* Back Button */}
      <div className="mt-10 text-center">
       <button className=' bg-orange-500 rounded-sm p-4'>
       <Link to="/" className="text-indigo-600 font-semibold hover:text-indigo-800">Back to Home</Link>
        </button> 
      </div>

      
    </div>
  );
};

export default WebDevelopment;
